import React from "react";
import styles from "./ListingCardD.module.css";

const FeatureTags = ({ tags }) => {

  return tags?.length > 0 ? (
    <div className={styles.featureTags}>
      {tags.map((item, index) => (
        <span key={item}>
          {item}
          {index !== tags.length - 1 && (
            <span className={styles.separator}> · </span>
          )}
        </span>
      ))}
    </div>
  ) : null;
};

export default FeatureTags;
