export const difficultyColorsMap = {
  Beginner: {
    primaryColor: "textGreen",
    secondaryColor: "lightBgGreen",
    icon: "MdOutlineSignalCellularAlt2Bar",
  },
  Intermediate: {
    primaryColor: "darkMud",
    secondaryColor: "seoLightBgYellow",
    icon: "MdOutlineSignalCellularAlt",
  },
  Advanced: {
    primaryColor: "deepRed",
    secondaryColor: "bgLightRed",
    icon: "FaSignal",
  },
};

export const defaultProps = {
  image: {
    id: 38,
    url: "https://outscal-content.s3.ap-south-1.amazonaws.com/2200324_ad47a1e8be.jpg",
  },
  level: [
    {
      id: 21,
      label: "Beginner",
    },
    {
      id: 22,
      label: "Intermediate",
    },
    {
      id: 23,
      label: "Advanced",
    },
  ],
  benefits: [
    {
      id: 6282,
      text: "Cancel Anytime",
      icon: "BsCheck2Circle",
      link: null,
    },
  ],
  title: "Outscal: PRO Plan",
  // uspTag: "Free Trial",
  section_id: "course-subscription",
  keys: {
    keys: [
      "Free Trial",
      "No Credit Card Required",
      "20+  Projects",
      "1000+ Hours of Content",
    ],
  },
  scroll_id: null,
  // premiumTagText: "NEW",
  ctaLink: "/courses",
  isFeatured: true,
  hasCertificate: true,
  hasPlacement: false,
  preTitle: "Access courses created by Game Devs",
  showOn: {
    data: [],
  },
  styleClass: "",
};
