import React from "react";
import styles from "./ListingCardD.module.css";
import ConditionLinkView from "@/mvcComponents/v2/Commons/UtilityComponents/ConditionLink.view";

const ListingCardDHeader = ({ link, title, description }) => {
  return (
    <ConditionLinkView link={link} onClick={(e) => e.preventDefault()}>
      <p className={`${styles.title} ${styles["trunc-2"]}`}>{title}</p>
      {description && (
        <div className={`${styles.description} ${styles["trunc-2"]}`}>
          {description}
        </div>
      )}
    </ConditionLinkView>
  );
};

export default ListingCardDHeader;
