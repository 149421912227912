import React from "react";
import {
  CardListWrapper,
  Heading,
  Subheading,
  Header,
  CTA,
} from "./CoursesListing.styles";
import CoursesListingFilters from "./CoursesListingFilters/CoursesListingFilters.view";
import ContentListingA from "../ContentListingA/ContentListingA.view";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";
import DynamicIcon from "@/mvcComponents/Commons/DynamicIcons/DynamicIcons.controller";
import FlexGrid from "@/mvcComponents/Commons/FlexGrid/FlexGrid.view";
import NotFoundView from "@/mvcComponents/Commons/NotFound/NotFound.view";

const CoursesListingView = ({
  heading,
  listPlugs,
  data,
  onCtaClick,
  id,
  setFilters,
  filters,
  appliedFilters,
  setAppliedFilters,
  isLoggedIn,
  assessments,
  failedAssessmentCTA,
  addAssessmentsToData,
  openInPopup,
  showCoursesFilters,
  cardComponent: CardComponent,
  subHeading,
  columns,
  showSubscription,
  defaultFilters,
  ctaIcon,
  ctaText,
  separator,
}) => {
  return (
    <section id={id}>
      <Header
        alignItems="center"
        justifyContent="space-between"
        padding={"0 0 12px 0"}
      >
        <Heading ctaText={ctaText}>{heading}</Heading>
        {ctaText && (
          <CTA
            data-analytics={[UIElements.BUTTON, `${id}`]}
            onClick={onCtaClick}
          >
            {ctaIcon && <DynamicIcon icon={ctaIcon} />}
            {ctaText}
          </CTA>
        )}
      </Header>
      {subHeading && <Subheading>{subHeading}</Subheading>}
      <CardListWrapper gap="12px">
        {showCoursesFilters && (
          <CoursesListingFilters
            id={id}
            defaultFilters={defaultFilters}
            setFilters={setFilters}
            filters={filters}
            appliedFilters={appliedFilters}
            setAppliedFilters={setAppliedFilters}
          />
        )}
        {showSubscription && <ContentListingA />}
        {data?.length > 0 ? (
          <FlexGrid
            alignment="space-evenly"
            listData={addAssessmentsToData(data, assessments)}
            columns={columns}
            component={CardComponent}
            listPlugs={listPlugs}
            gap={16}
            name={id}
            customCardData={{
              isLoggedIn,
              columns,
              failedAssessmentCTA,
              openInPopup,
            }}
            separator={separator}
          />
        ) : (
          <>
            {showCoursesFilters ? (
              <NotFoundView
                heading={" No Matching Courses"}
                subHeading={
                  "Oops! We couldn’t find any courses that match your filters. Try adjusting them to explore more options!"
                }
              />
            ) : null}
          </>
        )}
      </CardListWrapper>
    </section>
  );
};

export default CoursesListingView;
