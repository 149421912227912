import React from "react";
import ListingCardView from "./ListingCard.view";
import { useRouter } from "next/router";
import {
  courseListingMap,
  defaultFailedAssessmentCta,
} from "./ListingCard.model";
import { convertStringToSlug } from "@/outscal-commons-frontend/utils/stringOperations";
import AssessmentListingCard from "../AssessmentListingCard/AssessmentListingCard.view";
import { removeHashFromUrl } from "@/utils/urlHelper";

const ListingCard = ({
  name = "",
  slug = "",
  feeType = "",
  link = "",
  type = "",
  premiumTagText = "",
  level = {},
  uspTag = "",
  title = "",
  access_marker: accessMarker = "BASIC",
  skills = {},
  keys = {},
  popupUrl = "",
  isLoggedIn = false,
  failedAssessmentCTA = defaultFailedAssessmentCta,
  styleClassType = "",
  openInPopup = false,
  hasPlacement = false,
  contentCount = 0,
  contentIncludes = "",
  columns = 1,
  scroll_id = "",
  image = {},
  imgTag = "",
  description = "",
  assessmentItem = {}
}) => {
  const { data = [] } = skills || {};
  const router = useRouter();

  const disableClick = (e) => e.preventDefault();

  const handleListingCardClick = (e) => {
    e.preventDefault();
    if (popupUrl && openInPopup) {
      router.push(
        {
          pathname: router.pathname,
          query: { ...router.query, popup: popupUrl },
        },
        undefined,
        { shallow: true }
      );
    } else {
      let finalLink =
        router.pathname === "/learning-paths/[...learningPathSlug]"
          ? `${removeHashFromUrl(router.asPath)}/${slug}`
          : link;
      finalLink && window.open(finalLink, "_self") 
    }
  };

  const sharedProps = {
    name,
    slug,
    image,
    hasPlacement,
    link,
    type,
    level,
    uspTag,
    feeType,
    premiumTagText,
    contentCount,
    contentIncludes,
    title,
    popupUrl,
    keys,
    isLoggedIn,
    failedAssessmentCTA,
    styleClassType,
    columns,
    skills: data,
    description,
    imgTag,
    scroll_id:
      isLoggedIn && type === "Assessment" ? `${scroll_id}_new` : scroll_id,
    premiumTagText,
    disableClick,
    isProPerk: accessMarker === "PRO",
    handleListingCardClick,
    analyticsText: `${convertStringToSlug(title)}_${type}_${name}`,
    ...courseListingMap[type],
  };

  if (type === "Assessment") {
    return (
      <AssessmentListingCard
        {...sharedProps}
        assessmentItem={assessmentItem}
        failedAssessmentCTA={failedAssessmentCTA}
      />
    );
  }

  return <ListingCardView {...sharedProps} />;
};

export default ListingCard;
