import React from "react";
import styles from "./IconTextPills.module.css";
import { themeColorsMap } from "@/outscal-commons-frontend/utils/commons";
import DynamicIcon from "../DynamicIcons/DynamicIcons.controller";

const IconTextPills = ({ pills = [] }) => {
  return pills.length ? (
    <div className={styles.pillsContainer}>
      {pills.map(({ id, text, icon, theme }) => {
        return (
          <div
            key={id}
            className={styles.pill}
            style={{
              color: themeColorsMap[theme]?.textColor,
              backgroundColor: themeColorsMap[theme]?.bgColor,
            }}
          >
            {icon && <DynamicIcon icon={icon} />}
            <span className={styles.text}>{text}</span>
          </div>
        );
      })}
    </div>
  ) : null;
};

export default IconTextPills;
