import React from "react";

const Separator = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "0.5px",
        backgroundColor: "#ccc",
      }}
    />
  );
};

export default Separator;
