import React from "react";
import styles from "./ListingCard.module.css";
import { Text } from "@/outscal-commons-frontend/Styled/TextStyles";
import FeatureTags from "./FeatureTags.controller";
import Image from "next/image";
import DynamicIcon from "@/mvcComponents/Commons/DynamicIcons/DynamicIcons.controller";
import { difficultyColorsMap, typeLabelMap } from "./ListingCard.model";
import ConditionLinkView from "@/mvcComponents/v2/Commons/UtilityComponents/ConditionLink.view";
import { Theme } from "@/outscal-commons-frontend/Styled";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";

const ListingCardView = ({
  image = {},
  level = [],
  title = "",
  description = "",
  uspTag = "",
  analyticsText,
  feeType = "",
  link = "",
  type = "",
  keys = {},
  scroll_id = null,
  premiumTagText = "",
  disableClick,
  handleListingCardClick,
  isFeatured = false,
  hasCertificate = false,
  hasPlacement = false,
  contentCount = 0,
  contentIncludes = "",
  styleClass = "",
  skills = [],
  imgTag = "image",
  ctaText = "",
  columns = 1,
}) => {
  return (
    <div
      className={`${styles.root} ${
        isFeatured ? styles.featured : styles[`${styleClass}-root`]
      } ${link ? styles.pointer : ""}`}
      id={scroll_id}
      onClick={handleListingCardClick}
      data-analytics={[UIElements.CARD, analyticsText]}
      view-analytics={[UIElements.CARD, analyticsText]}
    >
      <div
        className={styles.cardDetailsWrapper}
        style={{
          padding: "12px",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div className={styles.cardDetailsWrapper}>
            <div className={styles.imgWrapper}>
              {image?.url && (
                <Image
                  width={180}
                  height={124}
                  className={styles.cardImg}
                  src={image.url}
                  alt={title}
                  quality={30}
                />
              )}
              {imgTag && (
                <div
                  className={`${styles.imgTag} 
            ${styles[`${styleClass}-pill-featured`]}
            `}
                >
                  {imgTag}
                </div>
              )}
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                {feeType === "Free" && (
                  <div className={styles.type}>{feeType}</div>
                )}
                {type && feeType === "Free" && (
                  <div className={styles.type}>·</div>
                )}
                {level &&
                  (level.length >= 2
                    ? [level[0], level[level.length - 1]]
                    : level
                  ).map(
                    (difficulty, index) =>
                      difficulty?.label && (
                        <React.Fragment key={difficulty.id}>
                          <div
                            className={`${styles.type} ${styles.iconText}`}
                            style={{
                              color:
                                Theme.colors[
                                  difficultyColorsMap[difficulty.label]
                                    ?.primaryColor
                                ],
                            }}
                          >
                            <DynamicIcon
                              icon={difficultyColorsMap[difficulty.label]?.icon}
                            />
                            {difficulty.label}
                          </div>
                          {index === 0 && level.length >= 2 && (
                            <span className={styles.type}>to</span>
                          )}
                        </React.Fragment>
                      )
                  )}
                {(typeLabelMap[type] ??
                  (typeLabelMap[type] === undefined ? type : null)) &&
                  level.length > 0 && <div className={styles.type}>·</div>}

                {(typeLabelMap[type] ??
                  (typeLabelMap[type] === undefined ? type : null)) && (
                  <div className={styles.type}>
                    {typeLabelMap[type] ?? type}
                  </div>
                )}
                {contentCount && contentIncludes && (
                  <>
                    <div className={styles.type}>·</div>
                    <div className={styles.type}>
                      {contentCount} {contentIncludes}
                    </div>
                  </>
                )}
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <ConditionLinkView link={link} onClick={disableClick}>
                  <Text fontSize="xl" fontWeight="midBold">
                    {title}
                  </Text>
                  {type == "LearningPath" && description && (
                    <div
                      className={`${styles.description} ${
                        columns === 1 ? styles["trunc-1"] : styles["trunc-2"]
                      }`}
                    >
                      {description}
                    </div>
                  )}
                </ConditionLinkView>
                <FeatureTags skills={skills} />
                <FeatureTags tags={keys?.keys} />
                {(hasCertificate || hasPlacement) && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "6px",
                      flexWrap: "wrap",
                    }}
                  >
                    <>
                      {hasCertificate && (
                        <>
                          <div className={styles.type}>·</div>
                          <div className={`${styles.type} ${styles.iconText}`}>
                            <DynamicIcon icon="PiCertificate" color="#8b3dff" />
                            Certificate Eligible
                          </div>
                        </>
                      )}
                      {hasPlacement && (
                        <>
                          <div className={styles.type}>·</div>
                          <div className={`${styles.type} ${styles.iconText}`}>
                            <DynamicIcon
                              icon="BsCheck2Circle"
                              color="#067519"
                            />
                            Job Placement Support
                          </div>
                        </>
                      )}
                    </>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {ctaText && link && (
          <a
            href={link}
            className={styles.ctaButton}
            data-analytics={[UIElements.BUTTON, `${analyticsText}_cta`]}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {ctaText}
          </a>
        )}
      </div>
      {uspTag && (
        <div
          className={`${styles.uspTag} ${
            styles[
              isFeatured ? `${styleClass}-pill-featured` : `${styleClass}-pill`
            ]
          }`}
        >
          {uspTag}
        </div>
      )}
      {premiumTagText && (
        <div className={styles.premiumTag}>{premiumTagText}</div>
      )}
    </div>
  );
};

export default ListingCardView;
