import React from "react";
import styles from "./NotFound.module.css";

const NotFoundView = ({ heading, subHeading }) => {
  return (
    <div className={styles.root}>
      <h3 className={styles.heading}>{heading}</h3>
      <div className={styles.subheading}>{subHeading}</div>
    </div>
  );
};

export default NotFoundView;
