import { isMobile } from "@/hooks/useMobileBreakPoint";
import ProTag from "@/mvcComponents/Commons/ProTag/ProTag.controller";
import { getBaseUrl } from "@/utils/urlHelper";

class ListingCardDController {
  constructor() {
    this.levelTheme = {
      beginner: "green",
      intermediate: "yellow",
      advanced: "red",
    };
  }

  handleListingCardClick = (e, popupUrl, openInPopup, link, slug, router) => {
    e.preventDefault();

    if (popupUrl && openInPopup) {
      router.replace(
        {
          pathname: router.pathname,
          query: { ...router.query, popup: popupUrl },
        },
        undefined,
        { shallow: true }
      );
    } else {
      let finalLink =
        router.pathname === "/learning-paths/[...learningPathSlug]"
          ? `${getBaseUrl(router.asPath)}/${slug}`
          : link;

      if (finalLink) {
        router.push(finalLink);
      }
    }
  };

  getLevelPill = (level) => {
    if (!level?.length) return null;

    const levelText =
      level.length >= 2
        ? `${level[0].label} to ${level[level.length - 1].label}`
        : level[0].label;

    return {
      text: levelText,
      link: null,
      subText: null,
      theme: this.levelTheme[level[0].label.toLowerCase()] || "gray",
    };
  };

  getPillsList = (level, pills, skills, subscribedCourses, type) => {
    const levelPill = this.getLevelPill(level);

    return [
      ...(subscribedCourses && type == "Project" && isMobile
        ? [
            {
              text: (
                <p>
                  {" "}
                  <ProTag fontSize="14px" padding="4px 0 0" /> Start
                </p>
              ),
              link: null,
              subText: null,
              theme: "highlightPurple",
            },
          ]
        : []),
      ...(levelPill ? [levelPill] : []),
      ...(skills?.length
        ? skills.map((skill) => ({
            text: skill.display_name,
            link: null,
            subText: null,
            theme: "blue",
          }))
        : []),
      ...pills,
    ];
  };
}

export default ListingCardDController;
