import { getQueryFromFilter } from "@/utils/Jobs/jobQuery";
import { FormData } from "./CoursesListingFilters.model";
import UTMManager from "@/Logic/ClientLogic/Services/UTMManager";

class CoursesListingFiltersController {
  constructor(router, setAppliedFilters) {
    this.router = router;
    this.setAppliedFilters = setAppliedFilters;
  }

  setFiltersInQuery(filtersStateData) {
    const query = getQueryFromFilter(filtersStateData);
    Object.keys(this.router?.query).forEach((key) => {
      if (this.router?.pathname.includes(key)) {
        query[key] = this.router.query[key];
      }
    });

    this.setAppliedFilters({ ...filtersStateData });
    this.router.push(
      { pathname: this.router.pathname, query: query },
      undefined,
      {
        shallow: true,
      }
    );
  }

  scrollOnFilterChange(id) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  setFiltersForResultsPage(defaultFilters = {}) {
    Object.keys(defaultFilters).length &&
      this.router.push(
        { pathname: router.pathname, query: defaultFilters },
        undefined,
        {
          shallow: true,
        }
      );
  }

  isPaidLead = (router) => {
    return (
      UTMManager?.instance?.sessionUtm?.utm_medium === "paid" &&
      (router.pathname === "/courses" ||
        router.pathname.includes("/learning-paths") ||
        router.asPath.includes("/game-development-courses") ||
        router.pathname === "/course/[courseSlug]")
    );
  };

  convertFiltersToObjects(obj = {}) {
    const transformedFilters = {};

    if (Object.keys(obj).length < 1) {
      return transformedFilters;
    }

    for (const key in obj) {
      if (FormData[key] && FormData[key].optionsList) {
        const selectedIds = obj[key].split(",").map((id) => id.trim());
        const optionsList = FormData[key].optionsList;

        transformedFilters[key] = selectedIds
          .map((id) => {
            const option = optionsList.find((opt) => opt.id === id);
            return option ? { id: option.id, label: option.label } : null;
          })
          .filter(Boolean);
      } else {
        transformedFilters[key] = obj[key];
      }
    }

    return transformedFilters;
  }

  filterObjectByKeys(obj, keys) {
    return keys.reduce((acc, key) => {
      if (obj.hasOwnProperty(key)) {
        acc[key] = obj[key];
      }
      return acc;
    }, {});
  }
}

export default CoursesListingFiltersController;
