import React, { useEffect, useRef } from "react";
import { FormData, freeCourseFilterData } from "./CoursesListingFilters.model";
import JobFiltersView from "@/mvcComponents/Commons/Jobs/JobFilterSection/JobFilters/JobFilters.view";
import styles from "./CoursesListingFilters.module.css";
import { useRouter } from "next/router";
import CoursesListingFiltersController from "./CoursesListingFilters.controller";
import ClientAppManager from "@/Logic/ClientLogic/Services/ClientAppManager";
import Constant from "@/staticData/constant";
import { checkObjectDeepEquality } from "@/outscal-commons-frontend/utils/dataFormating";

const CoursesListingFilters = ({
  id,
  setFilters,
  filters,
  appliedFilters,
  setAppliedFilters,
  defaultFilters,
}) => {
  const router = useRouter();
  const coursesListingFiltersController = useRef(
    new CoursesListingFiltersController(router, setAppliedFilters)
  );

  useEffect(() => {
    if (router.isReady) {
      setFilters(
        coursesListingFiltersController.current.convertFiltersToObjects(
          coursesListingFiltersController.current.filterObjectByKeys(
            defaultFilters ? defaultFilters : router.query,
            Object.keys(FormData)
          )
        )
      );
      setAppliedFilters({
        ...coursesListingFiltersController.current.convertFiltersToObjects(
          coursesListingFiltersController.current.filterObjectByKeys(
            defaultFilters ? defaultFilters : router.query,
            Object.keys(FormData)
          )
        ),
      });

      coursesListingFiltersController.current.setFiltersForResultsPage(
        defaultFilters
      );
    }
  }, [router.isReady]);

  return (
    <div
      className={styles.courseFilters}
      // style={{
      //   top:
      //     ClientAppManager.instance?.pageManager?.pageData?.showNavbarOn?.data?.some(
      //       (item) => item.slug === "none"
      //     ) || coursesListingFiltersController.current.isPaidLead(router)
      //       ? "0px"
      //       : `${Constant.NAVBAR_HEIGHT}`,
      // }}
    >
      <JobFiltersView
        filterUIObj={Object.values(FormData)}
        setFilters={(name, value) => {
          setFilters((prevState) => ({ ...prevState, [name]: value }));
        }}
        styleOverrides={{
          padding: "12px 8px 8px 8px",
          backgroundColor: "var(--border-gray)",
          borderRadius: "var(--br-xxs)",
          border: "2px solid var(--black)",
          display: "flex",
          justifyContent: "center",
        }}
        clearFilters={() => {
          setAppliedFilters({});
          setFilters({});
          coursesListingFiltersController.current.setFiltersInQuery({});
        }}
        filtersRef={null}
        filters={filters}
        setFiltersInQuery={() => {
          coursesListingFiltersController.current.setFiltersInQuery(filters);
          !checkObjectDeepEquality(filters, appliedFilters) &&
            coursesListingFiltersController.current.scrollOnFilterChange(id);
        }}
        hasFreeFilter={!!filters["feeType"]}
        onToggleClick={() => {
          !checkObjectDeepEquality(filters, appliedFilters) &&
            coursesListingFiltersController.current.scrollOnFilterChange(id);
          if (!!filters["feeType"]) {
            const { feeType, ...filteredState } = filters;
            setFilters({ ...filteredState });
            coursesListingFiltersController.current.setFiltersInQuery({
              ...filteredState,
            });
          } else {
            setFilters({
              ...filters,
              ...freeCourseFilterData,
            });
            coursesListingFiltersController.current.setFiltersInQuery({
              ...filters,
              ...freeCourseFilterData,
            });
          }
        }}
      />
    </div>
  );
};

export default CoursesListingFilters;
