import React from "react";
import UTMManager from "@/Logic/ClientLogic/Services/UTMManager";

const ListingCardHOC = (WrappedComponent) => {
  return (props) => {
    if (UTMManager?.instance?.sessionUtm?.utm_medium === "paid") {
      const { feeType, ...restProps } = props;
      return <WrappedComponent {...restProps} />;
    }

    return <WrappedComponent {...props} />;
  };
};

export default ListingCardHOC;
