import React from "react";
import styles from "./FlexGrid.module.css";
import JobListPlugs from "../Jobs/JobListAndDetails/JobList/JobListPlugs/JobListPlugs.view";
import Separator from "./Separator.view";
import JobsListPlugLogic from "../Jobs/JobListAndDetails/JobList/JobListPlugs/JobListPlugs.controller";
import useAuthActions from "@/store/actions/authAction";
const jobsListPlugLogic = new JobsListPlugLogic();

const FlexGrid = ({
  alignment = "center",
  listData = [],
  columns = 1,
  component: Component,
  gap = 16,
  name = "",
  customCardData = {},
  listPlugs = [],
  separator = false,
}) => {
  const { auth } = useAuthActions();

  return (
    <div
      className={styles.flexgrid}
      style={{
        justifyContent: alignment,
        gap,
      }}
    >
      {listData?.map((item, index) => {
        const itemStyle = {
          flex: `1 1 calc(${100 / columns}% - ${gap}px)`,
          maxWidth: `calc(${100 / columns}% - ${gap}px)`,
        };
        return (
          <React.Fragment key={"Courses-listing-" + index}>
            <div
              className={styles.listItem}
              style={itemStyle}
            >
              <Component name={name} {...item} {...customCardData} />
            </div>
            {separator && <Separator />}
            {listPlugs?.length > 0 &&
              (() => {
                const plug = jobsListPlugLogic.getSelectedPlug(
                  index + 1,
                  listPlugs,
                  auth
                );

                if (!plug) return null;

                return (
                  <>
                    <JobListPlugs jobIndex={index + 1} plugConfig={listPlugs} />
                    {separator && <Separator />}
                  </>
                );
              })()}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default FlexGrid;
