import React, { useRef } from "react";
import styles from "./ListingCardD.module.css";
import FeatureTags from "./FeatureTags.controller";
import Image from "next/image";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";
import ListingCardDHeader from "./ListingCardDHeader.view";
import ListingCardDController from "./ListingCardD.controller";
import { useRouter } from "next/router";
import { convertStringToSlug } from "@/outscal-commons-frontend/utils/stringOperations";
import IconTextPills from "@/mvcComponents/Commons/IconTextPills/IconTextPills.view";
import ListingCardHOC from "./ListingCardHOC.view";
import useUserActions from "@/store/actions/UserProfileActions/userAction";
import ProTag from "@/mvcComponents/Commons/ProTag/ProTag.controller";

const ListingCardD = ({
  image = {},
  level = [],
  slug = "",
  title = "",
  description = "",
  uspTag = "",
  link = "",
  popupUrl = "",
  openInPopup = false,
  keys = {},
  scroll_id = null,
  type = "",
  name = "",
  pills = [],
  feeType,
  skills = {},
}) => {
  const router = useRouter();
  const { user, getActiveCourseSubscription } = useUserActions();
  const listingCardDController = useRef(new ListingCardDController());

  return (
    <div
      className={`${styles.root} ${link ? styles.pointer : ""}`}
      id={scroll_id}
      onClick={(e) =>
        listingCardDController.current.handleListingCardClick(
          e,
          popupUrl,
          openInPopup,
          link,
          slug,
          router
        )
      }
      data-analytics={[
        UIElements.CARD,
        `${convertStringToSlug(title)}_${type}_${name}`,
      ]}
      view-analytics={[
        UIElements.CARD,
        `${convertStringToSlug(title)}_${type}_${name}`,
      ]}
    >
      <div className={styles.cardDetailsWrapper}>
        <div className={styles.imgWrapper}>
          {image?.url && (
            <Image
              width={160}
              height={96}
              className={styles.cardImg}
              src={image.url}
              alt={title}
            />
          )}
          <div className={`${styles.imgTagWrapper}`}>
            {skills?.data?.map((skill) =>
              skill?.skill_icon?.url ? (
                <Image
                  key={skill?.id}
                  height={28}
                  width={28}
                  src={skill?.skill_icon?.url}
                  alt={skill?.display_name}
                  className={styles?.skillIcon}
                  quality={100}
                />
              ) : null
            )}
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.content}>
            <ListingCardDHeader
              link={link}
              title={title}
              description={description}
            />
            <FeatureTags tags={keys?.keys} />
            <IconTextPills
              pills={listingCardDController.current.getPillsList(
                level,
                pills,
                skills?.data,
                getActiveCourseSubscription(user),
                type
              )}
            />
          </div>
          {getActiveCourseSubscription(user) && type == "Project" && (
            <div className={`${styles.unlockedText}`}>
              <ProTag fontSize="16px" padding="4px 0 0" /> Start
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ListingCardHOC(ListingCardD);
